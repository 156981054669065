import { ElementState } from './element.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const elementFeatureKey = 'element';
export const selectElementFeature = createFeatureSelector<ElementState>(elementFeatureKey);

export const selectAllElements = createSelector(
  selectElementFeature,
  (elementState: ElementState) => elementState.elements
);
export const selectLoadingElements = createSelector(
  selectElementFeature,
  (elementState: ElementState) => elementState.loadingElements
);
export const selectElement = createSelector(
  selectElementFeature,
  (elementState: ElementState) => elementState.element
);
export const selectLoadingElement = createSelector(
  selectElementFeature,
  (elementState: ElementState) => elementState.loadingElement
);
export const selectElementUpdated = createSelector(
  selectElementFeature,
  (elementState: ElementState) => elementState.elementUpdated
);
export const selectElementDeleted = createSelector(
  selectElementFeature,
  (elementState: ElementState) => elementState.elementDeleted
);
